.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.home-container01 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0,0%,100%,.8);
}
.home-text {
  text-align: center;
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text01 {
  color: var(--dl-color-secondary-700);
  width: 628px;
  height: 53px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}
.home-text02 {
  color: var(--dl-color-secondary-600);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
}
.home-container02 {
  display: flex;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-container03 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-navlink {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-link {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-features {
  width: 100%;
  height: 501px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-link1 {
  display: contents;
}
.home-component3 {
  text-decoration: none;
}
.home-image {
  top: 0px;
  left: auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 80vh;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}
.home-banner {
  width: 100%;
  height: 568px;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #5f82b9;
}
.home-text03 {
  color: #ffffff;
  width: 898px;
  height: 133px;
  font-size: 3rem;
  text-align: center;
}
.home-text04 {
  color: rgb(255, 255, 255);
  max-width: 1400px;
  margin-top: 32px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 32px;
}
.home-link2 {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link2:hover {
  transform: scale(1.02);
}
.home-container04 {
  width: 100%;
  height: 256px;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-stats {
  width: 100%;
  height: 235px;
  display: grid;
  padding: 48px;
  grid-gap: 32px;
  max-width: 1400px;
  align-self: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  color: rgb(255, 124, 76);
  font-size: 3rem;
}
.home-text08 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text09 {
  color: rgb(255, 124, 76);
  font-size: 3rem;
}
.home-text10 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text11 {
  color: rgb(255, 124, 76);
  font-size: 3rem;
}
.home-text12 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text13 {
  color: #FF7C4C;
  font-size: 3rem;
}
.home-text15 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-testimonials {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
}
.home-bottom-wave-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -5px;
  position: absolute;
  object-fit: cover;
}
.home-image01 {
  top: auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-top-wave-image {
  top: -5px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-container06 {
  width: 100%;
  height: 756px;
  display: flex;
  z-index: 1;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: 15rem;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: 79px;
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: 15rem;
  justify-content: space-between;
}
.home-container07 {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-container08 {
  width: 603px;
  height: 215px;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text16 {
  color: var(--dl-color-gray-white);
}
.home-text17 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-logos {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container09 {
  flex: 0 0 auto;
  display: flex;
  min-width: 400px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-container11 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image02 {
  width: 100%;
}
.home-container12 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image03 {
  width: 100%;
}
.home-container13 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image04 {
  width: 100%;
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-container15 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image05 {
  width: 100%;
  object-fit: cover;
}
.home-container16 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image06 {
  width: 100%;
}
.home-container17 {
  width: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image07 {
  width: 100%;
}
.home-container18 {
  flex: 0 0 auto;
  width: 50%;
  height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container19 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image08 {
  width: 100%;
}
.home-container20 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image09 {
  width: 100%;
  object-fit: cover;
}
.home-contaier {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-container21 {
  width: 923px;
  height: 210px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container22 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius75);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
}
.home-icon {
  fill: var(--dl-color-gray-800);
  width: 24px;
  height: 24px;
}
.home-text20 {
  margin-top: var(--dl-space-space-unitandahalfunit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text21 {
  color: var(--dl-color-secondary-600);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text24 {
  color: rgb(103, 116, 142);
  font-weight: 400;
  background-color: rgb(255, 255, 255);
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-container01 {
    max-width: 960px;
  }
  .home-card {
    width: 100%;
  }
  .home-features {
    height: 812px;
    max-width: 960px;
  }
  .home-banner {
    height: 579px;
  }
  .home-text04 {
    max-width: 100%;
  }
  .home-text05 {
    max-width: 100%;
  }
  .home-text07 {
    text-align: center;
  }
  .home-text09 {
    text-align: center;
  }
  .home-text11 {
    text-align: center;
  }
  .home-text13 {
    text-align: center;
  }
  .home-container06 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    justify-content: center;
  }
  .home-container07 {
    align-items: center;
  }
  .home-container08 {
    margin-right: 0px;
  }
  .home-logos {
    display: none;
  }
  .home-contaier {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .home-card {
    align-items: center;
  }
  .home-text02 {
    text-align: center;
    margin-right: 0px;
    padding-right: 0px;
  }
  .home-features {
    max-width: 720px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-image {
    display: none;
  }
  .home-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text03 {
    width: 767px;
    height: 141px;
  }
  .home-text04 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text05 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container04 {
    height: 361px;
  }
  .home-stats {
    padding-left: 32px;
    padding-right: 32px;
    grid-template-columns: 1fr 1fr;
  }
  .home-text08 {
    text-align: center;
  }
  .home-text10 {
    text-align: center;
  }
  .home-text12 {
    text-align: center;
  }
  .home-text15 {
    text-align: center;
  }
  .home-container06 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .home-container21 {
    width: 80%;
  }
  .home-text24 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .home-card {
    padding: var(--dl-space-space-unit);
    position: relative;
  }
  .home-text01 {
    width: 326px;
    height: 144px;
    align-self: center;
    text-align: center;
  }
  .home-container02 {
    align-items: center;
    flex-direction: column;
  }
  .home-container03 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-features {
    width: 100%;
    height: 1442px;
  }
  .home-banner {
    height: 1051px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-text03 {
    color: rgb(255, 255, 255);
    width: 374px;
    height: 391px;
    font-size: 3rem;
    font-style: inherit;
    font-weight: 700;
  }
  .home-container04 {
    height: 718px;
    justify-content: center;
  }
  .home-container05 {
    height: 661px;
  }
  .home-stats {
    height: 259px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
    grid-template-columns: 1fr;
  }
  .home-bottom-wave-image {
    height: 55px;
  }
  .home-container06 {
    height: 592px;
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-container08 {
    height: 340px;
  }
  .home-text16 {
    width: 406px;
    height: 128px;
    align-self: center;
    text-align: center;
  }
  .home-text17 {
    width: 317px;
    height: 180px;
    align-self: center;
    text-align: center;
    margin-bottom: 0px;
  }
  .home-contaier {
    height: 488px;
  }
  .home-text24 {
    text-align: center;
  }
}
